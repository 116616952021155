@import "common/index"

.header
  width: 100%
  background: $bg_color
  border-bottom: 1px solid $color_black
  position: fixed
  top: 0
  left: 0
  z-index: 4

.header-wrapper
  max-width: $max_wrapper
  margin: 0 auto
  display: flex
  flex-wrap: wrap
  align-items: center
  flex-direction: row
  background: $bg_color
  padding: 10px 10px

  +min-w($tablet_m)
    justify-content: space-between
    padding: 10px 0


.header__left
  width: 100%
  margin-bottom: 15px

  +min-w($tablet_m)
    width: 25%
    margin-bottom: 0
    display: flex
    flex-direction: row
    justify-content: space-between


  a
    display: flex
    justify-content: center

.header__right
  display: flex
  width: 100%
  justify-content: space-around
  position: fixed
  bottom: 0
  left: 0
  padding: 8px 0
  border-top: 1px solid $color_black
  background: $bg_color

  +min-w($tablet_m)
    display: flex
    width: 25%
    flex-direction: row
    position: static
    padding: 0 10px 0 0
    border: none
    justify-content: end

  +min-w(1380px)
    padding: 0

.header__logo
  width: 150px
  display: block
  object-fit: contain
  cursor: pointer
  margin: 0 auto

  +min-w($tablet_m)
    margin: 0 auto 0 0
    padding-left: 10px

  +min-w(1380px)
    padding-left: 0

.header__menu-btn
  display: none
  width: 101px
  height: 35px
  background: $base_color
  padding: 10px 8px
  text-align: right
  cursor: pointer
  border-radius: 10px
  align-items: center
  justify-content: end
  color: $bg_color
  font-size: 15px
  line-height: 18px
  position: relative
  font-weight: 600

  +min-w($tablet_m)
    display: flex

  &:before
    content: ''
    width: 24px
    height: 24px
    position: absolute
    left: 7px
    top: 6px
    background: url("../images/decor/category-btn-white.svg") no-repeat center
    background-size: contain
    transition: 0.2s

  &--close
    &:before
      background: url("../images/decor/close-white.svg") no-repeat center
      background-size: contain
      transition: 0.2s


.header__cart
  width: 30px
  height: 30px
  background: url("../images/decor/cart.svg") no-repeat center
  background-size: contain
  cursor: pointer
  outline: none
  position: relative

  +min-w($tablet_m)
    margin-right: 20px

  span
    text-decoration: none
    color: $bg_color
    width: 18px
    height: 18px
    background: $base_color
    position: absolute
    top: -6px
    right: -9px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50%
    font-size: 14px
    line-height: 14px

.header__prof
  width: 30px
  height: 30px

  &--logout
    background: url("../images/decor/out.svg") no-repeat center
    background-size: contain
    cursor: pointer
    outline: none

  &--login
    background: url("../images/decor/login.svg") no-repeat center
    background-size: contain
    cursor: pointer
    outline: none

.header__user-setting
  width: 30px
  height: 30px
  background: url("../images/decor/setting.png") no-repeat center
  background-size: contain
  cursor: pointer
  outline: none
