@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap')
@import "common/style-reset"
@import "common/index"
@import 'loaders.css/src/animations/pacman.scss'

body
  font-family: 'Roboto', sans-serif
  background: $bg_color
  color: $text_color

html, body
  scrollbar-color: $base_color_red $base_color
  scrollbar-width: auto

.App
  display: flex
  flex-direction: column
  justify-content: space-between
  min-height: 100vh

.content
  max-width: $max_wrapper
  margin: 0 auto 0 auto
  padding: 130px 0 50px 0
  width: 100%

  +min-w($tablet_m)
    margin-bottom: 60px
    padding: 104px 0 60px 0

  +min-w(1380px)
    padding: 104px 0 90px 0

  //&.product-content
  //  padding: 0 10px

.loader-hidden
  display: none

.loader-active
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100%

.pacman
  max-width: max-content

::-webkit-scrollbar
  width: 10px

::-webkit-scrollbar-track
  -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset
  background-color: #f9f9fd
  border-radius: 10px


::-webkit-scrollbar-thumb
  border-radius: 10px
  background: linear-gradient(180deg, $base_color_red, $base_color)