@import "../../styles/common/index"

.bestsellers
  margin-bottom: 40px

  +min-w($tablet_m)
    margin-bottom: 50px

.bestsellers__title
  padding: 0 10px
  font-style: normal
  font-weight: 600
  font-size: 18px
  line-height: 22px
  color: $text_color
  margin: 0 0 10px 0

  +min-w($tablet_m)
    font-size: 25px
    line-height: 28px
    margin: 0 0 20px 0

.bestsellers__wrapper-card
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: flex-start
