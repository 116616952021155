@import "../../styles/common/index"

.grade-stars
  display: flex
  align-items: center

  .product-text-item__stars &
    align-items: flex-start

  span
    width: 16px
    height: 16px
    margin-right: 1px
    display: block

    +min-w($tablet_s)
      margin-right: 3px

  .grade-stars__stars-check
    background: url("../../images/decor/star-check.svg") no-repeat center
    background-size: contain

  .grade-stars__stars-not-check
    background: url("../../images/decor/star-not-check.svg") no-repeat center
    background-size: contain

.reviews-form__grade

  span
    width: 26px
    height: 26px
    cursor: pointer
