@import "common/index"

.admin-page
    width: 100%
    min-height: 100vh
    display: flex
    background: $bg_color_admin
    position: relative

.admin-main
    flex-grow: 1

.admin-main__title
    font-style: normal
    text-align: center
    font-weight: 600
    font-size: 18px
    line-height: 20px
    color: $text_color
    margin: 20px 0 40px 0

    +min-w($tablet_s)
        font-size: 23px
        line-height: 25px

.admin-main__content
    display: flex
    flex-direction: column

    +min-w($tablet_s)
        flex-direction: row
        padding: 0 20px

.admin-main__content-left
    width: 100%

    +min-w($tablet_s)
        flex-grow: 1
        margin-right: 50px

.admin-main__content-right
    width: 100%
    order: -1

    +min-w($tablet_s)
        min-width: 300px
        width: 300px
        order: 1

.admin-main__content-table
    width: 100%
    table-layout: fixed

    &-id
        width: auto

    &-dell
        cursor: pointer

    border-spacing: 0
    empty-cells: hide

    td
        padding: 10px 20px
        text-align: center
        border-bottom: 1px solid #F4EEE8
        transition: all 0.5s linear
        width: calc(100% / 6)
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
        vertical-align: middle

        &:first-child
            text-align: left
            color: #3D3511
            font-weight: bold

        &:nth-child(even)
            background: $base_color_red

        &:nth-child(odd)
            background: #D1C7BF

        img
            width: 50px
            height: 50px
            object-fit: contain

    th
        padding: 10px 20px
        color: #3D3511
        border-bottom: 1px solid #F4EEE8

        &:nth-child(even)
            background: $base_color

        &:nth-child(odd)
            background: #D1C7BF

.admin-main__content-form
    border: 1px solid #F4EEE8
    padding: 10px 5px
    box-sizing: border-box

.admin-main__content-form
    display: flex
    flex-direction: column

    label
        display: flex
        justify-content: space-between
        margin-bottom: 20px
        font-size: 12px
        line-height: 15px

    input, button
        font-size: 12px
        line-height: 15px
        padding: 2px 5px
        border-radius: 5px
        outline: none
        border: 1px solid $text_color

    button
        cursor: pointer
        padding: 10px 0
        text-align: center

.admin-main__form-add
    display: flex
    flex-direction: column
    width: 100%
    max-width: 500px
    margin: 0 auto
    border: 1px solid $color_black
    border-radius: 20px
    padding: 20px 10px

    label
        display: flex
        justify-content: space-between
        margin-bottom: 20px
        font-size: 19px
        line-height: 25px

    input, button
        font-size: 16px
        line-height: 19px
        padding: 2px 5px
        border-radius: 5px
        outline: none
        border: 1px solid $text_color

    button
        cursor: pointer
        padding: 10px 0
        text-align: center

    .admin-main__form-add-file
        input
            border: none

.admin-main__forms
    display: flex
    justify-content: space-between
    flex-wrap: wrap

.admin-main__form-add-product-type
    margin: 0 auto 50px auto