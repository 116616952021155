@import "../../styles/common/index"

.deals
  width: 100%
  display: flex
  flex-direction: column
  background: #ffffff
  padding: 10px 0 0 0
  border-radius: 5px
  margin-bottom: 40px
  overflow: hidden

  +min-w($tablet)
    flex-direction: row
    border: 1px solid #d9dade
    padding: 0

  +min-w($tablet_m)
    margin-bottom: 50px

  .swiper-slide
    width: auto

.deals__title
  margin: 0 0 20px 0
  padding: 0 0 0 10px
  box-sizing: border-box

  +min-w($tablet)
    width: 179px
    border-right: 1px solid #d9dade
    padding: 20px 0 0 20px
    margin: 0

  +min-w(1340px)
    width: 281px

.deals__text, .deals__time
  font-weight: 600
  font-size: 18px
  line-height: 22px
  color: #1C1C1C
  margin-bottom: 10px

  +min-w($tablet)
    font-size: 20px
    line-height: 28px
    margin-bottom: 20px

.deals__time
  font-size: 20px
  line-height: 22px
  text-transform: uppercase

  +min-w($tablet)
    font-size: 22px
    line-height: 24px

  span
    color: $color_red
    font-weight: 700
    font-size: 24px
    line-height: 28px


.deals__offers

  +min-w($tablet)
    width: calc(100% - 179px)

  +min-w(1340px)
    width: calc(100% - 281px)


.deals-slider__item
  padding: 14px 15px
  background: #FFFFFF
  width: 140px
  box-sizing: border-box
  border-top: 1px solid #d9dade
  border-bottom: 1px solid #d9dade
  border-right: 1px solid #d9dade
  display: block
  outline: none
  transition: 0.3s
  position: relative

  +min-w($tablet)
    width: 179px
    border-top: none
    border-bottom: none

    .swiper-slide:first-child &
      border-left: 1px solid #d9dade

  +min-w(1340px)
    width: 176.4px

    &:hover
      transform: translateY(-5px)


  a
    font-style: normal
    font-weight: 400
    font-size: 13px
    line-height: 16px
    color: $text_color
    text-align: center
    margin-bottom: 8px
    text-decoration: none
    display: block
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

    &:hover
      color: #031327

    &:before
      content: ''
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%

    +min-w($tablet_m)
      font-size: 16px
      line-height: 24px

  span
    display: block
    width: 61px
    margin: 0 auto
    text-align: center
    padding: 6px 0
    background: #FFE3E3
    border-radius: 29px
    letter-spacing: -0.2px
    color: #EB001B
    font-weight: 500
    font-size: 14px
    line-height: 17px

.deals-slider__photo
  width: 98px
  height: 98px
  margin: 0 auto 4px auto

  +min-w($tablet_m)
    width: 100px
    height: 120px
    margin: 0 auto 20px auto

  img
    display: block
    width: 100%
    height: 100%
    object-fit: contain
