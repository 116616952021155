@import "common/index"

.search-page__title
  font-style: normal
  font-weight: 700
  font-size: 20px
  line-height: 22px
  margin-bottom: 20px

  +min-w($tablet)
    font-size: 24px
    line-height: 29px
    margin-bottom: 40px

  +min-w($tablet_s)
    font-size: 35px
    line-height: 40px



.search-page__sort
  display: flex
  align-items: flex-end
  margin-bottom: 20px

  p
    font-size: 18px
    line-height: 22px
    color: $text_color
    margin: 0 15px 0 0

  select
    appearance: none
    background-color: transparent
    border: 1px solid $base_color
    outline: none
    margin: 0
    width: 100%
    font-family: inherit
    max-width: max-content
    padding: 5px 20px 5px 10px
    font-size: 18px
    line-height: 20px
    cursor: pointer
    border-radius: 5px

    option
      cursor: pointer

.search-page__wrapper
  display: flex

.search-page__filters
  width: 100%
  height: 100%
  margin-right: 30px
  flex-shrink: 0
  position: fixed
  top: 0
  left: 0
  z-index: 99999
  overflow: auto
  background: $bg_color
  display: none
  padding: 10px 5px

  &--open
    display: block

  +min-w($tablet_s)
    display: block
    width: 230px
    position: static
    z-index: auto

.show-filter-btn
  position: fixed
  width: 50px
  height: 50px
  transform: translateX(-50%)
  left: 50%
  bottom: 55px
  background: $color_black
  border-radius: 50%
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center

  span
    width: 30px
    height: 0px
    border: 1px solid $color_white
    margin-bottom: 5px

    &:last-child
      margin-bottom: 0

  +min-w($tablet_s)
    display: none

.close-filter-btn
  width: 50px
  height: 50px
  border-radius: 50%
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center
  margin: 0 auto
  background: $color_black url("../images/decor/close-white.svg") no-repeat center
  background-size: contain

  +min-w($tablet_s)
    display: none


.search-page__content
  flex-grow: 1

.search-page__stars
  display: flex
  flex-direction: row
  margin-bottom: 13px
  font-size: 19px
  line-height: 30px
  align-items: center

.search-page__star
  width: 30px
  height: 30px
  background: url("../images/decor/star-check.svg") no-repeat center
  background-size: contain

.search-page__stars-filter
  p
    font-size: 20px
    line-height: 22px
    margin-bottom: 15px

.search-page__devices
  display: flex
  flex-direction: row
  flex-wrap: wrap

.search-page__box
  width: 20px
  height: 20px
  border: 1px solid $base_color
  margin: 0 10px
  border-radius: 5px
  cursor: pointer

  &--check
    background: $base_color url("../images/decor/checkbox.svg") no-repeat center
    background-size: 70%
