@import "../../styles/common/index"

.product-card-short
  background: #FFFFFF
  border: 1px solid #DEE2E7
  border-radius: 6px
  width: calc(100%/12*6 - 8px)
  margin: 0 4px 14px
  text-decoration: none
  position: relative
  transition: 0.3s
  min-height: 200px
  display: flex
  justify-content: space-between
  flex-direction: column

  +min-w($tablet)
    min-height: 300px

  &:hover
    transform: translateY(-2px)

  +min-w(500px)
    width: calc(100%/12*4 - 8px)

  +min-w($tablet)
    width: calc(100%/12*3 - 8px)

  +min-w($tablet_s)
    width: calc(100%/12*2 - 8px)

  .similar-products__slider &
    width: 100%

  .search-page__content &

    +min-w($tablet_s)
      width: calc(100%/10*2 - 8px)



.product-card-short__photo
  width: 100%
  height: 100px
  margin-bottom: 10px
  flex-grow: 1

  img
    width: 100%
    height: 100%
    display: block
    object-fit: cover

.product-card-short__content
  padding: 6px 10px
  min-height: 84px
  display: flex
  flex-direction: column
  justify-content: flex-end

.product-card-short__price
  transition: 0.3s
  font-style: normal
  font-weight: 600
  font-size: 16px
  line-height: 22px
  color: $text_color
  margin: 0 0 2px 0

  +min-w($tablet_m)
    font-size: 19px
    line-height: 25px

  span
    font-weight: 400
    margin-left: 4px
    color: #8B96A5
    text-decoration: line-through
    padding: 0 5px

.product-card-short__discount-wrapper
  display: flex
  margin-bottom: 4px
  font-weight: 400
  font-size: 15px
  line-height: 19px

.product-card-short__discount
  color: $color_red

.product-card-short__old-price
  text-decoration: line-through

.product-card-short__title
  transition: 0.3s
  font-style: normal
  font-weight: 400
  font-size: 15px
  line-height: 16px
  color: #8B96A5
  outline: none
  cursor: pointer
  display: block
  text-decoration: none
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

  +min-w($tablet_m)
    font-size: 18px
    line-height: 22px

  &:before
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
