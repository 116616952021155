@import "../../styles/common/index"

.content.special
  h2
    font-size: 25px
    line-height: 30px
    margin-bottom: 20px
    font-weight: 800

    +min-w($tablet_m)
      font-size: 35px
      line-height: 40px
      margin-bottom: 30px

  h3
    font-size: 22px
    line-height: 28px
    margin-bottom: 10px
    font-weight: 600

    +min-w($tablet_m)
      font-size: 25px
      line-height: 30px
      margin-bottom: 20px

  div
    margin-bottom: 20px

    +min-w($tablet_m)
      margin-bottom: 30px

  p
    font-size: 18px
    line-height: 20px
    font-weight: 400
    margin-bottom: 10px

    +min-w($tablet_m)
      font-size: 20px
      line-height: 24px


