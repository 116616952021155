@import "common/index"

.user-operation__title
  font-size: 20px
  line-height: 27px
  margin-bottom: 30px
  color: $text_color

  +min-w($tablet_m)
    font-size: 26px
    line-height: 34px

.user-operation__content-table
  width: 100%
  table-layout: fixed

  &-id
    width: auto

  a
    cursor: pointer
    color: $color_black

  border-spacing: 0
  empty-cells: hide
  color: $text_color

  thead
    background: #d7d7d7

  tbody
    tr:nth-child(2n)
      background: #d7d7d7

  td
    padding: 10px 20px
    text-align: center
    border-bottom: 1px solid #F4EEE8
    transition: all 0.5s linear
    width: calc(100% / 7)
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    vertical-align: middle

    img
      width: 50px
      height: 50px
      object-fit: contain

  th
    padding: 10px 20px
    color: #3D3511
    border-bottom: 1px solid #F4EEE8

.user-operation__sort
  margin-bottom: 30px
  display: flex
  flex-wrap: wrap

  label
    margin-right: 10px