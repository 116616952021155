@import "../../styles/common/index"

.seller-about
  +min-w($tablet)
    margin-bottom: 60px
    margin-bottom: 60px

  +min-w($tablet_s)
    display: flex
    justify-content: space-between

.seller-about__photo
  width: 150px
  height: 150px
  border-radius: 50%
  overflow: hidden
  margin: 0 auto 30px auto

  +min-w($tablet_s)
    width: 250px
    height: 250px

  img
    width: 100%
    height: 100%
    display: block
    object-fit: contain

.seller-about__text

  +min-w($tablet_s)
    width: calc(100% - 400px)


.seller-about__title
  font-style: normal
  font-weight: 500
  font-size: 20px
  line-height: 25px
  margin-bottom: 12px
  text-align: center
  color: $text_color

  +min-w($tablet)
    margin-bottom: 20px
    font-size: 22px
    line-height: 25px

  +min-w($tablet_s)
    font-weight: 600
    font-size: 35px
    line-height: 38px

.seller-about__description
  font-style: normal
  font-weight: 400
  font-size: 16px
  line-height: 24px
  letter-spacing: -0.2px
  text-align: left
  color: $text_color
  padding: 0 5px
  margin-bottom: 20px
  text-indent: 20px

  +min-w($tablet)
    padding: 0 10px
    font-size: 18px
    line-height: 20px

.seller-about__info-list
  padding: 0 5px

  +min-w($tablet)
    padding: 0 10px

  li
    margin-bottom: 10px
    padding-left: 25px
    font-size: 16px
    line-height: 24px
    letter-spacing: -0.2px
    position: relative

    &:before
      content: ''
      position: absolute
      left: 0
      top: 50%
      transform: translateY(-50%)

    +min-w($tablet)
      font-size: 18px
      line-height: 20px

    span
      font-style: italic


.seller-about__info-list--email
  &:before
    background: url("./../../images/seller/email.svg") no-repeat center
    background-size: contain
    width: 18px
    height: 18px

.seller-about__info-list--phone
  &:before
    background: url("./../../images/seller/phone.svg") no-repeat center
    background-size: contain
    width: 18px
    height: 18px

.seller-about__info-list--country
  &:before
    background: url("./../../images/seller/geo.svg") no-repeat center
    background-size: contain
    width: 22px
    height: 22px

.seller-grade
  padding: 0 5px
  margin: 20px 0

  .swiper-slide
    width: auto

  +min-w($tablet)
    padding: 0 10px
    display: flex
    flex-direction: row

.seller-grade__slider

  +min-w($tablet)
    width: calc(100% - 200px)

.seller-grade__title
  font-style: normal
  font-weight: 500
  font-size: 18px
  line-height: 20px
  margin-bottom: 20px

  +min-w($tablet)
    font-size: 20px
    line-height: 25px
    margin-bottom: 28px

  +min-w($tablet_s)
    font-weight: 600
    font-size: 25px
    line-height: 30px
    margin-bottom: 30px

.seller-comment
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  width: 100%
  max-width: 310px

  +min-w($tablet)
    max-width: 518px

.seller-comment__user
  display: flex
  flex-wrap: wrap
  margin-bottom: 20px
  max-width: 130px

.seller-comment__user-photo
  width: 60px
  height: 60px
  border-radius: 50%
  overflow: hidden

  img
    width: 100%
    height: 100%
    object-fit: cover
    display: block

.seller-comment__user-name
  font-style: normal
  font-weight: 400
  font-size: 16px
  line-height: 24px
  letter-spacing: -0.2px
  color: $text_color
  margin-left: 5px

.seller-comment__data
  display: flex
  flex-direction: column

  +min-w($tablet)
    flex-direction: row
    align-items: flex-start

  span
    display: block
    font-style: normal
    font-weight: 400
    font-size: 15px
    line-height: 20px
    letter-spacing: -0.2px
    color: #505050
    margin-top: 10px

    +min-w($tablet)
      margin-top: 0
      margin-right: 10px
      order: -1

.seller-comment__text
  width: 100%
  font-weight: 300
  font-size: 16px
  line-height: 18px
  margin-bottom: 20px

  +min-w($tablet_s)
    font-size: 16px
    line-height: 20px

.seller-grade__grade
  width: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  +min-w($tablet)
    width: 200px

.seller-grade__text, .seller-grade__grade-title
  font-weight: 400
  font-size: 18px
  line-height: 22px
  text-align: center

  +min-w($tablet_s)
    font-size: 20px
    line-height: 22px

.seller-grade__grade-title
  font-weight: 500
  margin-bottom: 10px

.seller-grade__text
  margin-top: 5px

.seller-grade__slider
  .swiper
    padding: 0 0 10px 0
  .swiper-pagination
    top: auto
    bottom: 0

  .swiper-pagination-bullet
    background: rgb(49, 49, 49)

    &-active
      background: rgba(49, 49, 49, 0.49)

.seller-devices
  display: flex
  flex-wrap: wrap
  justify-content: flex-start

