@import "common/index"

.add-product__title
  font-style: normal
  font-weight: 600
  font-size: 20px
  line-height: 26px
  color: $text_color
  text-align: center
  margin-bottom: 20px

  +min-w($tablet_m)
    font-size: 26px
    line-height: 34px
    margin-bottom: 30px


.add-product__form
  border: 1px solid $color_black
  border-radius: 20px
  padding: 10px 20px
  max-width: 1200px
  margin: 0 auto

  +min-w($tablet_m)
    padding: 40px 30px

.add-product__form-btn, .add-product__form-characteristics-btn
  background: $base_color
  padding: 10px 8px
  text-align: right
  cursor: pointer
  border-radius: 10px
  align-items: center
  justify-content: end
  color: $bg_color
  font-size: 15px
  line-height: 18px
  position: relative
  font-weight: 600
  display: block
  border: none
  outline: none

  +min-w($tablet_m)
    font-size: 18px
    line-height: 22px

  &:disabled,
  &[disabled]
    background: $text_color
    cursor: none

.add-product__form-title
  margin-bottom: 15px

.add-product__form  input
  border: 1px solid $color_black
  outline: none
  display: block
  background: $color_white
  border-radius: 10px
  font-size: 18px
  line-height: 22px
  padding: 5px 10px
  font-weight: 600
  margin-bottom: 10px

  +min-w($tablet_m)
    font-size: 22px
    line-height: 25px
    padding: 10px 15px


.add-product__form
  .add-product__form-error
    font-size: 18px
    line-height: 22px
    color: $color_red
    display: none

.add-product__form
  .add-product__form--error
    display: block


.add-product__form-name
  font-style: normal
  font-weight: 400
  font-size: 18px
  line-height: 22px
  color: $text_color
  display: block
  margin-bottom: 10px

  +min-w($tablet_m)
    font-size: 22px
    line-height: 25px
    margin-bottom: 15px

.add-product__form-type, .add-product__form-brand
  margin-bottom: 15px

  +min-w($tablet_m)
    margin-bottom: 35px

  span
    margin-bottom: 20px
    display: block

  .add-product__form-btn-modal
    display: block
    max-width: max-content
    border-radius: 5px
    padding: 15px 10px
    background: $base_color
    cursor: pointer
    border: none
    outline: none
    font-weight: 500
    font-size: 18px
    line-height: 20px
    color: #FFFFFF


.add-product__form-photo
  margin-bottom: 15px

  +min-w($tablet_m)
    margin-bottom: 35px


  .input-file
    position: relative
    display: inline-block

    input[type=file]
      position: absolute
      z-index: -1
      opacity: 0
      display: block
      width: 0
      height: 0
    input[type=file]:focus
      & + .input-file-btn
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25)

    &:hover
      .input-file-btn
        background-color: $base_color

    &:active
      .input-file-btn
        background-color: $base_color

    input[type=file]:disabled
      & + .input-file-btn
        background-color: #eee

    img
      width: 50px
      display: block
      object-fit: cover

  img
    width: 100%
    display: block
    object-fit: contain
    max-height: 100px

  .input-file-btn
    position: relative
    display: inline-block
    cursor: pointer
    outline: none
    text-decoration: none
    font-size: 14px
    vertical-align: middle
    color: rgb(255 255 255)
    text-align: center
    border-radius: 4px
    background-color: $base_color
    line-height: 22px
    height: 40px
    padding: 10px 20px
    box-sizing: border-box
    border: none
    margin: 0
    transition: background-color 0.2s

  .input-file-text
    padding: 0 10px
    line-height: 40px
    display: inline-block

.add-product__form-description, .add-product__form-characteristics
  margin-bottom: 15px

  +min-w($tablet_m)
    margin-bottom: 35px


.add-product__form-characteristics-btn
  background-color: $base_color
  max-width: max-content
  padding: 15px 60px 15px 20px
  position: relative
  font-weight: 400
  margin: 20px 0 0 0

  &:before
    content: '+'
    position: absolute
    top: 50%
    right: 20px
    font-weight: 500
    font-size: 38px
    line-height: 20px
    transform: translateY(-50%)

.add-product__form-characteristics-item
  margin: 0 0 20px 0
  display: flex
  flex-wrap: wrap

  input, span
    margin-right: 20px
