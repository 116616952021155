

.mark-down
  h1, h2, h3, h4, h5, h6
    font-weight: bold
    line-height: 1.2
    margin-bottom: 0.5em
  h1
    font-size: 2em
  h2
    font-size: 1.5em
  h3
    font-size: 1.17em
  h4
    font-size: 1em
  h5
    font-size: 0.83em
  h6
    font-size: 0.67em
  p
    margin-bottom: 1em
  ul, ol
    margin-bottom: 1em
  li
    margin-bottom: 0.5em
  strong
    font-weight: bold
  em
    font-style: italic
  blockquote
    margin: 1em 0
    padding: 0 1em
    border-left: 3px solid #ccc
