@import "../../styles/common/index"

.choose-popup
  display: flex
  align-items: center
  justify-content: center
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100vh
  z-index: 999
  background: $hsl_bg


.choose-popup__wrapper
  width: 100%
  background: $bg_color
  padding: 40px 30px
  height: auto
  max-width: 1000px
  border-radius: 10px
  position: relative
  max-height: 100vh
  overflow: auto

.choose-popup__title
  font-weight: 500
  font-size: 18px
  line-height: 22px
  margin-bottom: 15px

  +min-w($tablet_s)
    font-size: 24px
    line-height: 22px
    margin-bottom: 35px

.choose-popup__close
  position: absolute
  top: 35px
  right: 20px
  width: 30px
  height: 30px
  background: url("../../images/decor/close.svg") no-repeat center
  background-size: contain
  cursor: pointer

.choose-popup__data
  display: flex
  flex-wrap: wrap
  margin-bottom: 20px

  +min-w($tablet_s)
    margin-bottom: 30px

.choose-popup__item
  width: 100%
  margin-bottom: 10px
  display: flex

  +min-w($tablet_s)
    width: 20%
    margin-bottom: 15px

  span
    display: block
    width: 20px
    height: 20px
    border-radius: 3px
    border: 2px solid $color_black
    margin-right: 8px
    cursor: pointer

  p
    max-width: max-content
    cursor: pointer
    color: $color_black
    font-weight: 300
    font-size: 18px
    line-height: 22px

  &--select
    span
      background: $base_color

    p
      font-weight: 500


.choose-popup__btn
  max-width: max-content
  border-radius: 5px
  padding: 15px 10px
  background: #ec8b97
  cursor: pointer
  border: none
  outline: none
  font-weight: 500
  font-size: 18px
  line-height: 20px
  color: #FFFFFF