@import "../../styles/common/index"

.ad-slider
  width: 100%
  border-radius: 20px
  margin-bottom: 40px

  +min-w($tablet_m)
    margin-bottom: 50px

.ad-slider-item
  width: 100%
  height: 200px
  padding: 20px
  cursor: pointer
  transition: 0.5s

  &:hover
    -webkit-filter: brightness(85%)
    filter: brightness(85%)

  +min-w($tablet_m)
    height: 350px
    padding: 20px 30px

.ad-slider-one
  background: -webkit-linear-gradient(90deg, #d1bdcb, #d7d7d7, #d1bdcb)
  background: linear-gradient(90deg, #d1bdcb, #d7d7d7, #d1bdcb)
  position: relative

  &:before
    content: ''
    position: absolute
    top: 7px
    right: 26px
    width: 120px
    height: 120px
    background: url("./adSliderItems/img/decore-one.png") no-repeat center
    background-size: contain


  +min-w($tablet)
    &:before
      top: 10px
      right: 67px
      width: 181px
      height: 178px

  +min-w($tablet_m)
    &:before
      top: -5px
      right: 67px
      width: 330px
      height: 100%

.ad-slider-one__title
  font-size: 28px
  line-height: 32px
  font-weight: 800

  +min-w($tablet_m)
    font-size: 50px
    line-height: 55px

.ad-slider-one__subtitle
  font-size: 22px
  line-height: 25px
  font-weight: 400
  margin-bottom: 60px
  display: block

  +min-w($tablet_m)
    font-size: 45px
    line-height: 48px
    margin-bottom: 123px

.ad-slider-one__info
  font-size: 14px
  line-height: 33px
  font-weight: 600
  display: block
  text-transform: uppercase

  +min-w($tablet)
    font-size: 25px
    line-height: 28px

  +min-w(1150px)
    font-size: 38px
    line-height: 45px

.ad-slider-two
  background: $base_color_red
  background: -webkit-linear-gradient(to right, #ec8b97, #bfe9ff)
  background: linear-gradient(to right, #ec8b97, #bfe9ff)

.ad-slider-three
  background: url("./adSliderItems/img/decor-three-mob.jpg") no-repeat center
  background-size: cover

  +min-w($tablet)
    background: url("./adSliderItems/img/decore-three.jpg") no-repeat center
    background-size: contain


.ad-slider .swiper-button-prev, .ad-slider .swiper-button-next
  width: 45px
  height: 45px
  border-radius: 100%
  border: none
  cursor: pointer
  transition: .3s
  background: rgba(255, 255, 255, 0.23)
  box-shadow: none

  &:hover
    background: rgba(255, 255, 255, 0.51)

  &:after
    content: ''
    background: url("./adSliderItems/img/arrow.svg") no-repeat center
    background-size: contain
    width: 20px
    height: 20px

  &.swiper-button-disabled
    opacity: 0

.ad-slider .swiper-button-next

  &:after
    transform: rotate(180deg)

.ad-slider .swiper-pagination-bullet
  background: rgb(255, 255, 255)

  &-active
    background: rgba(255, 255, 255, 0.77)


