@import "../../styles/common/index"

.header__search
  border: 2px solid $base_color
  border-radius: 15px
  display: flex
  width: 100%
  margin-bottom: 10px
  padding: 4px 0
  position: relative

  +min-w($tablet_m)
    max-width: 648px
    margin: 0 20px
    width: calc(50% - 40px)

.header__search-input
  border: none
  outline: none
  border-radius: 15px
  flex: 1
  padding: 4px 7px
  font-family: 'Roboto', sans-serif
  font-size: 18px
  line-height: 23px
  font-weight: 400
  background: $bg_color

.header__btn-search, .header__search-clear
  cursor: pointer
  border: none
  outline: none
  width: 50px
  transition: 0.5s
  border-radius: 15px
  background: $bg_color

  svg
    max-width: 24px

.header__search-clear
  width: 34px
  display: flex
  justify-content: center
  align-items: center

.header__search-wrapper
  position: absolute
  top: 40px
  left: 0
  width: 100%

.header__search-item
  width: 100%
  display: flex
  flex-direction: row
  background: $bg_color
  padding: 5px 10px
  box-sizing: border-box
  border-radius: 15px
  border: 1px solid $base_color
  transition: 0.2s
  overflow: hidden
  position: relative

  &:hover
    background: rgb(239, 239, 239)

.header__search-item-photo
  margin-right: 10px
  width: 35px
  height: 35px

  img
    width: 100%
    object-fit: contain

.header__search-item-info
  display: flex
  flex-direction: column

  a
    font-size: 15px
    line-height: 18px
    color: $text_color
    text-decoration: none
    margin-bottom: 2px

    &:before
      content: ''
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      cursor: pointer

  span
    font-size: 13px
    line-height: 16px