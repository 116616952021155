@import "../../styles/common/index"

.check-block
  width: 30px
  height: 30px
  box-sizing: border-box
  border: 1px solid $color_black
  border-radius: 8px
  cursor: pointer

  &--check
    background: $base_color url("../../images/decor/checkbox.svg") no-repeat center
    background-size: 70% 70%
    border: none
