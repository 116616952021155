@import "../../../styles/common/index"

.description-product, .reviews-product, .questions-product
  padding: 0 10px
  margin-bottom: 20px

  +min-w($tablet_m)
    padding: 40px 20px 45px 20px
    border-radius: 5px
    box-shadow: $shadow_bg

body p.description-product__title, p.reviews-product__title, p.questions-product__title
  font-style: normal
  font-weight: 500
  font-size: 22px
  line-height: 25px
  margin-bottom: 14px
  color: $color_black

  +min-w($tablet_s)
    font-size: 22px
    line-height: 25px
    margin-bottom: 22px

  +min-w($desktop_m)
    font-size: 27px
    line-height: 28px
    margin-bottom: 22px
    font-weight: 700

.description-product__info
  position: relative
  margin-bottom: 40px

  +min-w($desktop_m)
    margin-bottom: 60px

  .mark-down
    height: auto
    max-height: 100px
    overflow: hidden
    font-style: normal
    font-weight: 400
    font-size: 16px
    line-height: 24px
    letter-spacing: -0.2px
    color: $text_color
    transition: max-height 0.4s cubic-bezier(0,1,0,1)
    position: relative
    margin-bottom: 10px

    &.mark-down--after:after
      content: ''
      position: absolute
      bottom: 0
      left: 0
      width: 100%
      height: 90px
      z-index: 5
      background-image: linear-gradient(180deg, hsla(0,0%,100%,0), #fff)
      transition: 0.4s

  &.info-open
    .mark-down
      max-height: 99999px
      transition: max-height 0.4s cubic-bezier(0,1,0,1)

      &.mark-down--after:after
        content: none

.description-product__wrapper
  display: flex
  flex-direction: column
  position: relative
  z-index: 2

.description-product__btn-info
  width: max-content
  padding: 5px 30px 5px 0
  color: $color_black
  font-weight: 600
  text-transform: uppercase
  //border: 1px solid $text_color
  border-radius: 5px
  cursor: pointer
  position: relative
  background: $bg_color
  display: block

  &:before
    content: ""
    position: absolute
    top: 5px
    right: 8px
    width: 15px
    height: 15px
    background: url("../../../images/decor/arrow.svg") no-repeat center
    background-size: contain

  &.open-info
    &:before
      transform: rotate(180deg)

.description-product__characteristics
  padding-bottom: 30px
  position: relative

  p
    font-weight: 400
    font-size: 16px
    line-height: 18px
    color: #173C5F


.description-product__characteristics-content
  height: auto
  max-height: 100px
  transition: max-height 0.4s cubic-bezier(0,1,0,1)
  overflow: hidden
  position: relative
  margin-bottom: 10px

  &.description-product__characteristics--after:after
    content: ''
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 90px
    z-index: 5
    background-image: linear-gradient(180deg, hsla(0,0%,100%,0), #fff)
    transition: 0.4s

  &.characteristics-open
    height: auto
    max-height: 99999px
    transition: max-height 0.4s cubic-bezier(0,1,0,1)

    &:after
      content: none

  dl
    display: flex
    align-items: flex-end
    margin-bottom: 30px
    margin-top: 0

  dt
    width: 50%
    position: relative
    margin-right: 5px

    &:before
      content: ''
      width: 100%
      height: 1px
      left: 0
      bottom: 0
      border-bottom: 0.5px dotted #173C5F
      position: absolute
      z-index: -1

    span
      background: $bg_color
      font-size: 14px
      line-height: 16px

  dd
    width: calc(50% - 5px)
    margin: 0
    font-size: 14px
    line-height: 16px

.product-text-item
  margin-bottom: 35px

  +min-w($tablet_m)
    margin-bottom: 65px

.product-text-item__user
  width: 100%
  display: flex
  justify-content: space-between
  margin-bottom: 15px

.product-text-item__user-data
  display: flex
  margin-bottom: 15px

.product-text-item__name
  font-style: normal
  font-weight: 400
  font-size: 16px
  line-height: 24px
  letter-spacing: -0.2px
  color: $text_color
  transition: 0.5s

  +min-w($tablet_m)
    font-weight: 600
    font-size: 21px
    line-height: 31px
    margin-left: 10px

.product-text-item__user-photo
  width: 60px
  height: 60px
  overflow: hidden
  border-radius: 50%
  margin-right: 10px

  img
    display: block
    object-fit: cover
    width: 100%
    height: 100%

.product-text-item__stars
  display: flex
  flex-direction: column

  +min-w($tablet)
    flex-direction: row

.product-text-item__date
  font-style: normal
  font-weight: 400
  font-size: 15px
  line-height: 20px
  letter-spacing: -0.2px
  color: #505050
  margin-top: 10px

  +min-w($tablet)
    margin-top: 0
    margin-right: 10px
    order: -1

.product-text-item__subtitle
  font-style: normal
  font-weight: 400
  font-size: 17px
  line-height: 20px
  margin-bottom: 8px

  +min-w($tablet_s)
    font-size: 20px
    line-height: 22px
    margin-bottom: 18px
    font-weight: 600

.product-text-item__text
  font-weight: 400
  font-size: 16px
  line-height: 18px
  margin-bottom: 20px

  +min-w($tablet_s)
    font-size: 16px
    line-height: 20px

.product-text-item__answer
  font-weight: 500
  font-size: 18px
  line-height: 22px
  width: max-content
  cursor: pointer
  position: relative
  margin-bottom: 20px

  &:before
    content: ''
    width: 0
    height: 1px
    background: $text_color
    bottom: -2px
    left: 0
    position: absolute
    transition: 0.5s

  +min-w($tablet_s)
    font-size: 19px
    line-height: 21px
    margin-bottom: 50px

    &:hover
      &:before
        width: 100%

.product-text-item__answer-form
  display: flex
  flex-direction: column
  align-items: flex-end
  width: 100%

  &--hidden
    display: none

  textarea
    margin-bottom: 10px
    width: 100%
    resize: vertical
    padding: 15px
    border-radius: 15px
    box-shadow: 4px 4px 10px rgba(0,0,0,0.06)
    height: 150px
    border: 1px solid $base_color

    &:focus
      border: none
      outline: 1px solid $base_color

  button
    max-width: max-content
    text-align: center
    border-radius: 5px
    padding: 15px 10px
    background: $base_color_red
    cursor: pointer
    border: none
    outline: none
    width: 100%
    font-weight: 600
    font-size: 15px
    line-height: 20px
    color: #FFFFFF

.reviews-product__score
  display: flex
  flex-direction: row
  flex-wrap: wrap

  .reviews-product__title
    width: 100%

  .product-text-item__answer
    font-weight: 400
    margin-right: 40px

.reviews-form
  display: flex
  align-items: center
  justify-content: center
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100vh
  z-index: 999
  background: $hsl_bg

.reviews-form__wrapper
  width: 100%
  background: $bg_color
  padding: 40px 30px
  height: auto
  max-width: 1000px
  border-radius: 10px
  position: relative
  max-height: 100vh
  overflow: auto

.reviews-form__title
  font-weight: 500
  font-size: 18px
  line-height: 22px
  margin-bottom: 15px

  +min-w($tablet_s)
    font-size: 24px
    line-height: 22px
    margin-bottom: 35px

.reviews-form__close
  position: absolute
  top: 35px
  right: 20px
  width: 30px
  height: 30px
  background: url("../../../images/decor/close.svg") no-repeat center
  background-size: contain
  cursor: pointer

.reviews-form__form
  div
    margin-bottom: 15px

    +min-w($tablet_s)
      margin-bottom: 25px

  p
    font-weight: 400
    font-size: 18px
    line-height: 22px
    margin-bottom: 5px

    +min-w($tablet_s)
      font-size: 20px
      line-height: 22px

  textarea
    width: 100%
    resize: vertical
    padding: 15px
    border-radius: 15px
    height: 100px

  button
    max-width: max-content
    border-radius: 5px
    padding: 15px 10px
    background: $base_color_red
    cursor: pointer
    border: none
    outline: none
    font-weight: 500
    font-size: 18px
    line-height: 20px
    color: #FFFFFF

    +min-w($tablet_s)
      padding: 20px 15px
      font-weight: 600
      font-size: 20px
      line-height: 25px

.reviews-answer
  margin-left: 20px

  +min-w($tablet_s)
    margin-left: 50px

.btn-change-reviews
  font-size: 20px
  line-height: 22px
  cursor: pointer
  margin: 0 10px 20px 0
  background: $base_color_red
  color: #FFFFFF
  padding: 15px 10px
  max-width: max-content
  border-radius: 5px

  +min-w($tablet_s)
    margin: 0 0 40px 0


.questions-item
  .questions-item
    margin-left: 50px