

$bg_color: rgb(255, 255, 255)
$bg_color_admin: rgb(202, 202, 202)
$text_color: #173C5F
$base_color: #d452fb
$base_color_red: #ec8b97
$color_white: #FFFFFF
$color_black: #1F1F1F
$color_red: #ff0000
$primary-color: $base_color
$hsl_bg: rgba(149, 147, 147, 0.56)

$tablet: 768px
$tablet_m: 1024px
$tablet_s: 1280px
$desktop_m: 1400px
$desktop: 1920px

$max_wrapper: 1340px

$shadow_bg: 0px 0px 51px 17px rgba(34, 60, 80, 0.2)